<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사용여부 -->
          <c-select
            :comboItems="useFlagItems"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="useFlag"
            label="시나리오 사용여부"
            v-model="searchParam.useFlag"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 비상조치계획서 목록 -->
    <c-table
      ref="table"
      title="비상조치 시나리오"
      :columns="grid.columns"
      :data="grid.data"
      rowKey="trainingScenarioId"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!-- 등록 -->
          <c-btn v-if="editable" label="LBLREG" icon="add" @btnClicked="linkClick" />
          <!-- 검색 -->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'emergenct-action-plan-docu',
  data() {
    return {
      grid: {
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '부서',
            align: 'center',
            sortable: false,
            style: 'width:90px',
          },
          {
            name: 'trainingScenarioName',
            field: 'trainingScenarioName',
            label: '시나리오명',
            align: 'left',
            sortable: false,
            type: 'link',
            style: 'width:400px',
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            // 사용여부
            label: 'LBLUSEFLAG',
            align: 'center',
            sortable: false,
            style: 'width:50px',
          },
        ],
        data: [],
      },
      searchParam: {
        plantCd: null,
        emergencyTypeCd: null,
        useFlag: 'Y',
      },
      useFlagItems: [
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      searchUrl: '',
      editable: true,
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        top: '',
        param: {},
        closeCallback: null,
      },
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.eap.emergTraining.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      // 비상조치계획서 상세 팝업
      this.popupOptions.title = '비상조치 시나리오 상세';
      this.popupOptions.param = {
        trainingScenarioId: row ? row.trainingScenarioId : '',
      };
      this.popupOptions.target = () => import(`${'./emergencyScenarioEvalDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
  }
};
</script>
